<template>
    <base-page>
        <titlu-pagina Titlu="Raport grad de incarcare" :AdaugaVisible='false'  />
        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix">
                <strong> Filtre </strong>
            </div>
            <div class="filtre">
                <el-form @submit.prevent.native='refresh_info()' label-position="top">
                    <el-row :gutter="20">

                        <el-col :span='4'>
                            <el-form-item label='Proiect' >
                                <SelectProiect v-model="Filters.NumeProiect" @selectie="handleSelectProiect" :IdProiect.sync="Filters.IdProiect" />
                            </el-form-item>
                        </el-col>

                        <el-col :span='4'>
                            <el-form-item label='Angajat' >
                                <SelectAngajat v-model="Filters.NumeAngajat" @selectie="handleSelectAngajat" :IdAngajat.sync="Filters.IdAngajat" arataDoarAngajatiEligibiliProiecte />
                            </el-form-item>
                        </el-col>  

                        <el-col :span='4'>
                            <el-form-item label='Luna' >
                                <el-date-picker v-model="Filters.Luna" type="month" value-format="yyyy-MM" placeholder="Alege luna"></el-date-picker>                                  
                            </el-form-item>
                        </el-col>
                                    
                        <el-col :span='24' >
                            <el-button @click="reset()" > Reset  </el-button>
                            <el-button type='primary' native-type='submit' > Aplica </el-button>
                        </el-col>

                    </el-row>
                </el-form>
            </div>
        </el-card>

        <el-table :data="Results" >
            <el-table-column type="expand" >
                <template slot-scope="props">
                    <el-card style="margin: 0 10px; background-color:oldlace">
                        <el-table :data="props.row.Proiecte" style="margin: 0 20px;">
                            <el-table-column type="expand" >
                                <template slot-scope="props">
                                    <el-table :data="props.row.Activitati" class="tabel_activitati">
                                        <el-table-column type="expand" >
                                            <template slot-scope="props">
                                                <el-table :data="props.row.Subactivitati" class="tabel_subactivitati">
                                                    <el-table-column prop="Nume" label="Subactivitate"></el-table-column>
                                                    <el-table-column prop="TotalOre" label="Ore"></el-table-column>  
                                                    <el-table-column prop="DataInceput" label="Data inceput"></el-table-column>                                   
                                                    <el-table-column prop="DataSfarsit" label="Data sfarsit"></el-table-column>                                    
                                                </el-table>
                                            </template>                            
                                        </el-table-column>
                                        <el-table-column prop="Nume" label="Activitate"></el-table-column>
                                        <el-table-column prop="TotalOre" label="Ore"></el-table-column>                                   
                                        <el-table-column prop="DataInceput" label="Data inceput"></el-table-column>                                   
                                        <el-table-column prop="DataSfarsit" label="Data sfarsit"></el-table-column>                                   
                                    </el-table>
                                </template>                            
                            </el-table-column>
                            <el-table-column prop="Nume" label="Proiect"></el-table-column>
                            <el-table-column prop="TotalOre" label="Ore"></el-table-column>
                        </el-table>
                    </el-card>                    
                </template>
            </el-table-column>
            <el-table-column prop='Nume' label='Nume'></el-table-column>
            <el-table-column prop='TipAngajat' label='Tip angajat'></el-table-column>
            <el-table-column prop='TotalOre' label='Total ore'></el-table-column>           
        </el-table>
       
    </base-page>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from "@/pages/BasePage";
    import TitluPagina from '@/widgets/TitluPagina';
    import Calendar from '@/components/Calendar';
    import moment from 'moment';


    export default {
        name: "raport_incarcare",
        extends: BasePage,
        components: {
            'base-page': BasePage,
            'titlu-pagina': TitluPagina,
            Calendar
        },
        data () {
            return {
                Results: [],
                base_url: '',
                Info: { angajati:[] },
                Filters: { Luna: moment().format("yyyy-MM"), IdAngajat:'-1', NumeAngajat:'',  IdProiect:'-1', NumeProiect:'' },                    
                OrderBy: { },
                PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
                
            }
        },
        methods: {

            tableRowClassName({row, rowIndex}) {
                console.log('rand',rowIndex);
                if (rowIndex === 0) {
                    return 'warning-row';
                } else if (rowIndex === 3) {
                    return 'success-row';
                }
                return '';
            },

            async get_info(){
                var response        = await this.post("raport_incarcare/get_info" );
                this.Info.angajati  = response.Angajati                
                this.refresh_info();
            },

            async refresh_info(){               
                var response        = await this.post("raport_incarcare/index_nou", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
                this.Results        = response.Results;              
                // this.select_menu_item('utilizatori');
            },
            reset(){
                this.Filters = {  Luna: '', IdAngajat:'-1', NumeAngajat:'',  IdProiect:'-1', NumeProiect:'' }
                this.refresh_info();
            },


            show_dialog(id){
                this.$refs.dlg.show_me(id);
            },
            handleSelectAngajat(item) {
                this.Filters.IdAngajat = item.id
            },
            handleSelectProiect(item) {
                this.Filters.IdProiect = item.id
            }
        },
        mounted(){
            this.base_url = settings.BASE_URL;
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>

    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }

    .tabel_activitati{
        margin: 0 20px;
        background: oldlace;
    }

    .tabel_subactivitati{
        margin: 0 20px;
    }

    .el-table .warning-row {
        background: oldlace;
    }

    .el-table .success-row {
        background: #f0f9eb;
    }

</style>
