<template>
  <div>
    <!-- <button @click="toggleWeekends">toggle weekends</button> -->
	<!-- {{Programari}}  -->
    <FullCalendar :options="calendarOptions" />
  </div>       
</template>
 
<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'


export default {
  components: { 
    FullCalendar // make the <FullCalendar> tag available
  },
  watch: {
	Programari(val){
		this.calendarOptions.events = val
	}
  },
  props:{ 
    Programari:{
      type: Array,
      required:false,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      calendarOptions: {
        plugins: [ dayGridPlugin, interactionPlugin ],
        initialView: 'dayGridMonth',
		dateClick: this.handleDateClick,   
		eventClick: this.handleEventClick,     
		events: this.Programari,
		editable: true,
		eventStartEditable:true,
		eventChange:this.handleEventChange,
		height: 520 
      }
    }
  },
  methods: {
    handleDateClick: function(arg) {
    	this.$emit('programareNoua', arg.dateStr)
    },
    handleEventClick: function(arg) {
      this.$emit('editProgramare', JSON.stringify(arg.event))			
    },
    handleEventChange(arg){
      this.$emit('mutaProgramare', JSON.stringify(arg.event))		
    }
  }
}
</script>
